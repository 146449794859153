import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import Tenets from '../components/tenets';

export default function Enquiries({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/');
  }, []);

  return (
    <>
      <SEO
        title="Tenets"
      />
      <div
        css={css`
          margin-top: 50vh;
        `}
      >
        <Tenets />
      </div>
    </>
  );
}
